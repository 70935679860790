import React from 'react';
import AddCustomer from '../Aminpannel/Customer/AddCustomer'; // Ensure paths are correct
import AddProduct from '../Aminpannel/Product/AddProduct';
import AddGaushala from '../Aminpannel/Gaushala/AddGaushala';
import AddSamvedana from '../Aminpannel/Samvedana/AddSamvedana';
import ListSamvedana from '../Aminpannel/Samvedana/ListSamvedana';
import ListGaushala from '../Aminpannel/Gaushala/ListGaushala';
import AddManasvardhan from '../Aminpannel/Manasvardhan/AddManasvardhan';
import ListManasvardhan from '../Aminpannel/Manasvardhan/ListManasvardhan';
import ListProduct from '../Aminpannel/Product/ListProduct';
import ListCustomer from '../Aminpannel/Customer/ListCustomer';
import AddSocialAwards from '../Aminpannel/Awards/Social/AddSocialAwards';
import ListSocialAwards from '../Aminpannel/Awards/Social/ListSocialAwards';
import AddProfesionalAwards from '../Aminpannel/Awards/Professional/AddProfesionalAwards';
import ListProfesionalAwards from '../Aminpannel/Awards/Professional/ListProfesionalAwards';
import ListVideo from '../Aminpannel/Samvedana/YouTube/ListVIdeo';
import AddNews from '../Aminpannel/News/News/AddNews';
import ListNews from '../Aminpannel/News/News/ListNews';
import AddSocial from '../Aminpannel/News/Social/AddSocial';
import ListSocial from '../Aminpannel/News/Social/ListSocial';
import AddVideoSam from '../Aminpannel/Samvedana/YouTube/AddVideoSam';
import AddHomePage from '../Aminpannel/HomePage/AddHomePage';
import ListHomePage from '../Aminpannel/HomePage/ListHomePage';

const MultiSectionForm = ({ activeSection }) => {
  const renderContent = () => {
    switch (activeSection) {
      case 'AddCustomer':
        return <AddCustomer />;
      case 'AddProduct':
        return <AddProduct />;
      case 'AddGaushala':
        return <AddGaushala />;
      case 'AddSamvedana':
        return <AddSamvedana />;
      case 'ListSamvedana':
        return <ListSamvedana />;
      case 'ListGaushala':
        return <ListGaushala />;
      case 'AddManasvardhan':
        return <AddManasvardhan />;
      case 'ListManasvardhan':
        return <ListManasvardhan />;
      case 'ListProduct':
        return <ListProduct />;
      case 'ListCustomer':
        return <ListCustomer />;
      case 'AddSocialAwards':
        return <AddSocialAwards />;
      case 'ListSocialAwards':
        return <ListSocialAwards />;
      case 'AddProfesionalAwards':
        return <AddProfesionalAwards />;
      case 'ListProfesionalAwards':
        return <ListProfesionalAwards />;
      case 'ListVideoSam':
        return <ListVideo />;
      case 'AddNews':
        return <AddNews />;
      case 'ListNews':
        return <ListNews />;
      case 'AddSocial':
        return <AddSocial />;
      case 'ListSocial':
        return <ListSocial />;
      case 'AddVideoSam':
        return <AddVideoSam />;
      case 'AddHomePage':
        return <AddHomePage />;
      case 'ListHomePage':
        return <ListHomePage />;
      default:
        return <div>Select a section to view details</div>;
    }
  };

  return (
    <div className="flex-1 p-6">
      <div className="container mx-auto">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold text-green-700">Admin Panel</h1>
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default MultiSectionForm;
