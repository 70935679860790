import React from 'react'
import AboutManasvardhan from './AboutManasvardhan'
import BodyManasvardhan from './BodyManasvardhan'
import GallaryManasvardhan from './GallaryManasvardhan'
import Navbar from '../../Navbar/Navbar'
import ManaswardhanHome from './ManaswardhanHome'
import Contactcomponent from '../../Contact/Contactcomponent'
import Contactform from './Contactform'

function HomeManasvardhan() {
  return (
    <div>
      <Navbar />
      <div className="mt-10 sm:pt-0">
        <ManaswardhanHome />
      </div>
      <div className="mt-8 space-x-5 flex justify-center">
        <h1 className="pb-5 text-3xl font-bold text-center text-teal-700">
          Work and Activities for Manasvardhan Punarvasan Kendra
        </h1>
      </div>
      <div>
        <BodyManasvardhan />
      </div>
      <div className="mt-10 sm:pt-0 mb-10">
        <GallaryManasvardhan />
      </div>
      <div>
        <Contactform />
      </div>
      <div className="mt-10 sm:pt-0 mb-10">
        <Contactcomponent />
      </div>
    </div>
  )
}

export default HomeManasvardhan
