import React, { useState } from 'react';

function AddCustomer() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zipcode: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Log formData to make sure it's being captured correctly
            console.log('Submitting form with data:', formData);

            const response = await fetch('https://samvedanafoundation.com/exe_files/add_customer.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // Use this for form data
                },
                body: new URLSearchParams(formData).toString() // Convert formData to URL-encoded string
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.msg || 'Failed to add customer');
            }

            const result = await response.json();
            console.log('API Response:', result); // Log the API response

            alert(result.msg || "Customer added successfully");

            // Reset form fields
            setFormData({
                name: '',
                email: '',
                mobile: '',
                address: '',
                city: '',
                state: '',
                country: '',
                zipcode: ''
            });

        } catch (error) {
            console.error("An error occurred while submitting the form:", error.message);
            alert(error.message || "Failed to add customer");
        }
    };

    return (
        <div>
            <div className="min-h-screen p-6 bg-gray-100 flex items-center justify-center">
                <div className="container max-w-screen-lg mx-auto">
                    <div>
                        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                                <div className="text-gray-600">
                                    <p className="font-medium text-lg">Customer Details</p>
                                    <p>Please fill out all the fields.</p>
                                </div>

                                <div className="lg:col-span-2">
                                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                        <div className="md:col-span-5">
                                            <label htmlFor="full_name">Full Name</label>
                                            <input type="text" name="name" id="full_name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="Enter Full Name" value={formData.name} onChange={handleChange} />
                                        </div>

                                        <div className="md:col-span-5">
                                            <label htmlFor="email">Email Address</label>
                                            <input type="text" name="email" id="email" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="email@domain.com" value={formData.email} onChange={handleChange} />
                                        </div>
                                        <div className="md:col-span-5">
                                            <label htmlFor="mobile">Mobile Number</label>
                                            <input type="tel" name="mobile" id="mobile" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="Enter Mobile Number" value={formData.mobile} onChange={handleChange} />
                                        </div>

                                        <div className="md:col-span-3">
                                            <label htmlFor="address">Address / Street</label>
                                            <input type="text" name="address" id="address" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="Enter Address" value={formData.address} onChange={handleChange} />
                                        </div>

                                        <div className="md:col-span-2">
                                            <label htmlFor="city">City</label>
                                            <input type="text" name="city" id="city" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="Enter City" value={formData.city} onChange={handleChange} />
                                        </div>

                                        <div className="md:col-span-2">
                                            <label htmlFor="country">Country / region</label>
                                            <input name="country" id="country" placeholder="Country" className="px-4 appearance-none outline-none text-gray-800 w-full bg-transparent" value={formData.country} onChange={handleChange} />
                                        </div>

                                        <div className="md:col-span-2">
                                            <label htmlFor="state">State / province</label>
                                            <input name="state" id="state" placeholder="State" className="px-4 appearance-none outline-none text-gray-800 w-full bg-transparent" value={formData.state} onChange={handleChange} />
                                        </div>

                                        <div className="md:col-span-1">
                                            <label htmlFor="zipcode">Zipcode</label>
                                            <input type="text" name="zipcode" id="zipcode" className="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="" value={formData.zipcode} onChange={handleChange} />
                                        </div>

                                        <div className="md:col-span-5 text-right">
                                            <div className="inline-flex items-end">
                                                <button onClick={handleSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCustomer;
