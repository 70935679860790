import React, { useState } from 'react';
import SidebarButtons from './Sidebarbuttons';
import MultiSectionForm from './Sidebar';


const Dashboard = () => {
  const [activeSection, setActiveSection] = useState('');

  return (
    <div className="flex">
      <aside className="w-80 bg-gray-100 shadow-lg">
        <SidebarButtons onSectionChange={setActiveSection} />
      </aside>
      <main className="flex-1 bg-gray-50 p-6">
        <MultiSectionForm activeSection={activeSection} />
      </main>
    </div>
  );
};

export default Dashboard;
