import React from 'react';
import { MdLocalPhone, MdOutlineMail } from 'react-icons/md';
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";

function Contactform() {
  return (
    <div className="p-2 md:p-8 lg:p-12">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 md:gap-20 bg-white text-[#333] font-[sans-serif] max-w-5xl mx-auto p-6 md:p-12">
        {/* Contact Info Section */}
        <div>
          <div className="mt-6">
            <h2 className="text-lg text-teal-700 font-extrabold">Call</h2>
            <ul className="mt-3">
              <li className="flex items-center">
                <div className="bg-gray-200 h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                  <p className="text-teal-700 font-bold" aria-label="Phone Icon">
                    <MdLocalPhone />
                  </p>
                </div>
                <a href="tel:+919423001415" className="text-gray-900 text-sm ml-3">
                  <small className="block font-bold">Call</small>
                  <strong className="text-base md:text-lg font-bold">+91 9423001415</strong>
                </a>
              </li>
            </ul>
          </div>

          <div className="mt-6">
            <h2 className="text-lg text-teal-700 font-extrabold">Email</h2>
            <ul className="mt-3">
              <li className="flex items-center">
                <div className="bg-gray-200 h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                  <p className="text-teal-700 font-bold" aria-label="Mail Icon">
                    <IoMdMail />
                  </p>
                </div>
                <a href="mailto:victorent197@gmail.com" className="text-gray-900 text-sm ml-3">
                  <small className="block font-bold">Mail</small>
                  <strong className="text-sm md:text-lg font-bold">samvedanafoundation.sangli@gmail.com</strong>
                </a>
              </li>
            </ul>
          </div>

          <div className="mt-6">
            <h2 className="text-lg text-teal-700 font-extrabold">Address</h2>
            <ul className="mt-3">
              <li className="flex items-center">
                <div className="bg-gray-200 h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                  <span className="text-teal-700 font-bold" aria-label="Location Icon">
                    <FaLocationDot />
                  </span>
                </div>
                <p className="text-gray-900 text-sm ml-3">
                  <small className="block font-bold">Address</small>
                  <strong className="text-base md:text-lg font-bold">
                    Gandhi Chowk Raj hotel Building, 458/1 B, Mangalwar Peth, Miraj, Maharashtra 416410
                  </strong>
                </p>
              </li>
            </ul>
          </div>

          <div className="flex space-x-4 mt-6">
            <a href="tel:+919423001415" className="bg-teal-500 hover:bg-teal-800 text-white py-2 px-4 rounded">
              Call
            </a>
            <a href="https://maps.app.goo.gl/YXH4JGETPqGcRBMB9" target="_blank" rel="noopener noreferrer" className="bg-teal-500 hover:bg-teal-800 text-white py-2 px-4 rounded">
              Get Direction
            </a>
          </div>
        </div>

        {/* Enquiry Form Section */}
        <form className="space-y-4">
          <h1 className="text-teal-700 font-bold text-xl">Enquire now</h1>
          <input type="text" placeholder="Name" className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-teal-700" />
          <input type="email" placeholder="Email" className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-teal-700" />
          <input type="text" placeholder="Subject" className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-teal-700" />
          <textarea placeholder="Message" rows="5" className="w-full rounded-md px-4 bg-gray-100 text-sm pt-3 outline-teal-700"></textarea>
          <button type="submit" className="text-white bg-teal-500 hover:bg-teal-800 font-semibold rounded-md text-sm px-4 py-3 w-full">
            Send
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contactform;
