import React from 'react';
import logo from '../../img/Samvdhana logo.png';
import { Link } from 'react-router-dom';

const SidebarSection = ({ title, links, open, setOpen }) => (
  <div className="flex flex-col border border-gray-200 rounded-lg shadow-md mb-2">
    <button
      onClick={() => setOpen(!open)}
      className="flex items-center justify-between p-2 text-gray-700 transition-colors duration-300 hover:bg-indigo-100 "
    >
      <span className="text-sm">{title}</span>
      <svg
        className={`w-4 h-4 transition-transform duration-300 transform ${open ? 'rotate-180' : ''}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
      </svg>
    </button>
    <div
      className={`transition-max-height duration-500 ease-in-out ${open ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}
      style={{ maxHeight: open ? '1000px' : '0' }} // adjust maxHeight as needed
    >
      <div className="mt-2 space-y-2 px-7">
        {links.map((link, index) => (
          <button
            key={index}
            onClick={link.onClick}
            className="block p-2 text-sm text-gray-600 transition-colors duration-200 rounded-md hover:text-gray-700"
          >
            {link.label}
          </button>
        ))}
      </div>
    </div>
  </div>
);

const SidebarButtons = ({ onSectionChange }) => {
  const [openSection, setOpenSection] = React.useState(null);

  const handleSectionToggle = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const handleLinkClick = (section) => {
    onSectionChange(section);
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100 p-4 md:w-80 md:bg-white">
      <div className='flex items-center justify-center mb-4'>
       <Link to="/"> <img src={logo} className="w-24 h-24 mb-4" alt="Logo" /></Link>
      </div>
      <nav aria-label="Main" className="flex-1 px-2 py-4 space-y-2 overflow-y-auto">
        <SidebarSection
          title="Samvedana"
          links={[
            { label: "Add Samvedana", onClick: () => handleLinkClick('AddSamvedana') },
            { label: "List Samvedana", onClick: () => handleLinkClick('ListSamvedana') },
            { label: "Add Video Samvedana", onClick: () => handleLinkClick('AddVideoSam') },
            { label: "List Video Samvedana", onClick: () => handleLinkClick('ListVideoSam') }
          ]}
          open={openSection === 'Samvedana'}
          setOpen={() => handleSectionToggle('Samvedana')}
        />
        <SidebarSection
          title="Gaushala"
          links={[
            { label: "Add Gaushala", onClick: () => handleLinkClick('AddGaushala') },
            { label: "List Gaushala", onClick: () => handleLinkClick('ListGaushala') }
          ]}
          open={openSection === 'Gaushala'}
          setOpen={() => handleSectionToggle('Gaushala')}
        />
        <SidebarSection
          title="Manasvardhan"
          links={[
            { label: "Add Manasvardhan", onClick: () => handleLinkClick('AddManasvardhan') },
            { label: "List Manasvardhan", onClick: () => handleLinkClick('ListManasvardhan') }
          ]}
          open={openSection === 'Manasvardhan'}
          setOpen={() => handleSectionToggle('Manasvardhan')}
        />
        <SidebarSection
          title="Product"
          links={[
            { label: "Add Product", onClick: () => handleLinkClick('AddProduct') },
            { label: "List Product", onClick: () => handleLinkClick('ListProduct') }
          ]}
          open={openSection === 'Product'}
          setOpen={() => handleSectionToggle('Product')}
        />
        <SidebarSection
          title="Customer"
          links={[
            { label: "Add Customer", onClick: () => handleLinkClick('AddCustomer') },
            { label: "List Customer", onClick: () => handleLinkClick('ListCustomer') }
          ]}
          open={openSection === 'Customer'}
          setOpen={() => handleSectionToggle('Customer')}
        />
        <SidebarSection
          title="News"
          links={[
            { label: "Add News", onClick: () => handleLinkClick('AddNews') },
            { label: "List News", onClick: () => handleLinkClick('ListNews') }
          ]}
          open={openSection === 'News'}
          setOpen={() => handleSectionToggle('News')}
        />
        <SidebarSection
          title="Social"
          links={[
            { label: "Add Social", onClick: () => handleLinkClick('AddSocial') },
            { label: "List Social", onClick: () => handleLinkClick('ListSocial') }
          ]}
          open={openSection === 'Social'}
          setOpen={() => handleSectionToggle('Social')}
        />
        <SidebarSection
          title="Professional Awards"
          links={[
            { label: "Add Professional Awards", onClick: () => handleLinkClick('AddProfessionalAwards') },
            { label: "List Professional Awards", onClick: () => handleLinkClick('ListProfessionalAwards') }
          ]}
          open={openSection === 'ProfessionalAwards'}
          setOpen={() => handleSectionToggle('ProfessionalAwards')}
        />
        <SidebarSection
          title="Social Awards"
          links={[
            { label: "Add Social Awards", onClick: () => handleLinkClick('AddSocialAwards') },
            { label: "List Social Awards", onClick: () => handleLinkClick('ListSocialAwards') }
          ]}
          open={openSection === 'SocialAwards'}
          setOpen={() => handleSectionToggle('SocialAwards')}
        />
        <SidebarSection
          title="Home Page"
          links={[
            { label: "Add Home Page", onClick: () => handleLinkClick('AddHomePage') },
            { label: "List Home Page", onClick: () => handleLinkClick('ListHomePage') }
          ]}
          open={openSection === 'HomePage'}
          setOpen={() => handleSectionToggle('HomePage')}
        />
      </nav>
    </div>
  );
};

export default SidebarButtons;
