import React from 'react';
import HomeNewsEvent from './HomeNewsEvent';
import Navbar from '../../Navbar/Navbar';

function NewsEvent() {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <Navbar />
      
      {/* Headline */}
      <div className="mt-28 sm:mt-28 lg:mt-32 px-4 sm:px-6 lg:px-8 text-center">
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-green-700">
          Headlines for Social Events and News
        </h1>
      </div>
      
      {/* News Events Section */}
      <div className="flex-grow mt-6 sm:mt-10 px-4 sm:px-6 lg:px-8">
        <HomeNewsEvent />
      </div>
    </div>
  );
}

export default NewsEvent;
