import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import UpperNav from './UpperNav';
import logo from '../../img/Samvdhana logo.png';
import { RiAdminFill } from "react-icons/ri";
import { MdLocalPhone, MdOutlineMail } from "react-icons/md";

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="fixed top-0 left-0 right-0 z-50 bg-white shadow-md">
            <UpperNav />
            <nav className="container mx-auto flex items-center justify-between py-4 px-6 md:px-12">
                {/* Logo */}
                <div className="flex items-center">
                    <img src={logo} alt="Samvdhana Foundation Logo" className="h-20 w-auto md:h-20 -my-20" />
                </div>

                {/* Mobile Menu Button */}
                <div className="md:hidden">
                    <button
                        onClick={toggleMobileMenu}
                        aria-expanded={menuOpen}
                        aria-controls="mobile-menu"
                        className="text-gray-800 hover:text-green-700 focus:outline-none"
                    >
                        <svg className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            {menuOpen ? (
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            ) : (
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            )}
                        </svg>
                    </button>
                </div>

                {/* Desktop Menu */}
                <div className="hidden md:flex space-x-8">
                    <ul className="flex items-center space-x-8">
                        {['/', '/about', '/activitygaushala', '/activitymanasvardhan', '/news', '/award', '/contact'].map((path, index) => (
                            <li key={index}>
                                <Link to={path} className="block py-2 text-gray-800 hover:text-violet-800 text-lg font-semibold">
                                    {['Home', 'About Us', 'Gaushala', 'Manasvardhan', 'News & Events', 'Awards', 'Contact Us'][index]}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>

            {/* Mobile Menu (appears under navbar) */}
            {menuOpen && (
                <div id="mobile-menu" className="md:hidden bg-white shadow-md transition-transform transform origin-top-right absolute w-full">
                    <ul className="flex flex-col items-start py-4 px-6 space-y-2">
                        {['/', '/about', '/activitygaushala', '/activitymanasvardhan', '/news', '/award', '/contact'].map((path, index) => (
                            <li key={index}>
                                <Link to={path} className="block py-2 text-gray-800 hover:text-violet-800 text-lg font-semibold">
                                    {['Home', 'About Us', 'Gaushala', 'Manasvardhan', 'News & Events', 'Awards', 'Contact Us'][index]}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <div className='flex justify-between px-8'>
                        <Link to='/contact'>
                            <button className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300'>
                                Donate Now
                            </button>
                        </Link>
                        <Link to='/login'>
                            <div className='text-black font-bold mr-20'><RiAdminFill /></div>
                        </Link>
                    </div>
                    <div className='flex items-center text-center space-x-2 md:space-x-4 py-4'>
                        <MdLocalPhone className='text-black text-xl font-bold' />
                        <p className='text-black text-lg font-bold'>+91 9423001415</p>
                    </div>
                    <div className='flex items-center space-x-2 md:space-x-4 pb-4'>
                        <MdOutlineMail className='text-black text-xl font-bold' />
                        <p className='text-black text-lg font-bold'>samvedanafoundation.sangli@gmail.com</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Navbar;
