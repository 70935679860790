import React from 'react';
import { MdLocalPhone, MdOutlineMail } from 'react-icons/md';
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";

function Contactform() {
    return (
        <div className='p-6 md:p-10 lg:p-12 bg-white'>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-6xl mx-auto  p-6 md:p-12 rounded-lg">
                {/* Contact Information */}
                <div>
                    {/* Call */}
                    <div className="mt-6">
                        <h2 className="text-lg text-orange-700 font-extrabold">Call</h2>
                        <ul className="mt-3">
                            <li className="flex items-center">
                                <div className="bg-gray-200 h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <p className="text-red-700 font-bold" aria-label="Phone Icon"><MdLocalPhone /></p>
                                </div>
                                <a href="tel:+919423001415" className="text-gray-900 text-sm ml-3">
                                    <small className="block font-bold">Call</small>
                                    <strong className="text-lg font-bold">+91 9423001415</strong>
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Email */}
                    <div className="mt-6">
                        <h2 className="text-lg text-orange-700 font-extrabold">Email</h2>
                        <ul className="mt-3">
                            <li className="flex items-center">
                                <div className="bg-gray-200 h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <p className="text-red-700 font-bold" aria-label="Mail Icon"><IoMdMail /></p>
                                </div>
                                <a href="mailto:victorent197@gmail.com" className="text-gray-900 text-sm ml-3">
                                    <small className="block font-bold">Mail</small>
                                    <strong className="md:text-lg text-sm font-bold">samvedanafoundation.sangli@gmail.com</strong>
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Address */}
                    <div className="mt-6">
                        <h2 className="text-lg text-orange-700 font-extrabold">Address</h2>
                        <ul className="mt-3">
                            <li className="flex items-center">
                                <div className="bg-gray-200 h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                                    <span className="text-red-700 font-bold" aria-label="Location Icon"><FaLocationDot /></span>
                                </div>
                                <div className="text-gray-900 text-sm ml-3">
                                    <small className="block font-bold">Address</small>
                                    <strong className="text-lg font-bold">Budhgaon - Kupwad Rd, Bisur, Maharashtra 416304</strong>
                                </div>
                            </li>
                        </ul>
                    </div>

                    {/* Buttons */}
                    <div className="flex space-x-4 mt-8">
                        <a href="tel:+919423001415" className="bg-orange-500 hover:bg-orange-700 text-white py-2 px-6 rounded-md text-sm">Call</a>
                        <a href="https://maps.app.goo.gl/6k9WWK5XFWF4geiH9" target="_blank" rel="noopener noreferrer" className="bg-orange-500 hover:bg-orange-700 text-white py-2 px-6 rounded-md text-sm">Get Directions</a>
                    </div>
                </div>

                {/* Contact Form */}
                <form className="space-y-6">
                    <h1 className="text-orange-700 font-bold text-2xl mb-6">Enquire Now</h1>
                    <input type="text" placeholder="Name" className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-none focus:ring-2 focus:ring-orange-500" />
                    <input type="email" placeholder="Email" className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-none focus:ring-2 focus:ring-orange-500" />
                    <input type="text" placeholder="Subject" className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-none focus:ring-2 focus:ring-orange-500" />
                    <textarea placeholder="Message" rows="5" className="w-full rounded-md px-4 bg-gray-100 text-sm pt-3 outline-none focus:ring-2 focus:ring-orange-500"></textarea>
                    <button type="submit" className="w-full text-white bg-orange-500 hover:bg-orange-700 py-3 rounded-md text-sm font-semibold">Send</button>
                </form>
            </div>
        </div>
    );
}

export default Contactform;
